<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('badges.mybadges') }}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/student/studenthome") {{ $t('badges.studentdashboard') }}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('badges.mybadges') }}

                div.row(style="margin-top:100px" )
                                    
                    div.col-md-3.col-sm-6.mb-4.dropright(v-for="badge in badges")
                        a(href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                            img.img-fluid(:src="badge.sBadge")
                            div.dropdown-menu(aria-labelledby="btnDropRight" v-if="badge.bActive")
                                a.dropdown-item(href="javascript:void(0);") {{ $t('badges.discharge') }}
                                a.dropdown-item(href="javascript:void(0);") {{ $t('badges.insert') }}
            

            div.footer-wrapper
                div.footer-section.ml-4
                    p © 2021 
                        a(target="_blank" href="https://etciberoamerica.com/") ETC Iberoamérica
                        template , {{ $t('badges.copy') }}.

</template>
<script>
/* eslint-disable */
export default {
    name: 'Badges',
  
    data() {
        return {
            badges: []

            
            }
    },
    methods: {
        async getKeyGroup() {
            await axios.get(apiURL + 'Student/getBadgesList?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.badges = response.data.badgesList
            } )
            .catch( (error)  => {
                console.log("error")
            } )            

        }
    },
    mounted() {
            this.checkPermisions(this.$router.currentRoute.path)
            this.getKeyGroup();
        }    
        
}
</script>